import axios from 'axios'

const getOneRequest = async (email, serialNumber) => {
    const payload = {
        email,
        serialNumber
    }

    const madeRequest = await axios.post('https://kuboto-backend.herokuapp.com/oneRequest', payload)
    const { data } = madeRequest

    return data
}

export default getOneRequest