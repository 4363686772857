import React from 'react'
import { Icon } from 'semantic-ui-react'

 const CloseWindowRight = () => {
    return (
        <div className="close-container">
            <Icon name="check circle outline" color="green" size="huge" className="success-icon-on-close"/>
        <div className="close-message-container">
            <p>You have now completed the details required, you may now close this window</p>
        </div>
        </div>
    )
}

export default CloseWindowRight
