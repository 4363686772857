import React from 'react'
import kubotoLogo from '../../../images/kuboto-logo.jpg'

 const MachineInfoLeft = () => {
    return (
        <div className="request-left-side">
        <div className="logo-container">
            <img src={kubotoLogo} alt='Kuboto logo' />
        </div>
        <div className="left-text">
                <p>Tell us about the machine</p>
                {/* <p>the machine</p> */}
            </div>
        </div>
    )
}

export default MachineInfoLeft
