import React from 'react'
import { Button, Modal, Icon } from 'semantic-ui-react'

import './comfirmMessage.css'

const ComfirmMessage = ({openComfirmationMessage, closeComfirmationMessage, open}) => {
    const closeModal = () => {
        closeComfirmationMessage()
    }

    return (
        <Modal
            size='tiny'
            onOpen={openComfirmationMessage}
            onClose={closeComfirmationMessage}
            open={open}
            className="comfirmation-message"
        >
            <Modal.Content>
                <h5 className='success-title'>Success!</h5>
                <p className="paragraph-message">
                    Thank you for submitting your details. We have emailed you a confirmation.
                </p>
                <Icon name="check circle outline" color="green" size="huge" className="success-icon"/>
            </Modal.Content>
            <Modal.Actions className="action-container">
                <Button className="close-modal-btn btn-color-green" onClick={closeModal}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ComfirmMessage
