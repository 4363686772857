import React from 'react'

import './button.css'

const BackButton = ({ prevForm, text, classStyles }) => {
    return (
        <div className="button-container">
            <button
                className={classStyles}
                onClick={prevForm && prevForm}
            >{text}</button>
        </div>
    )
}

export default BackButton