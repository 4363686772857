import React from 'react'
import { Button } from 'semantic-ui-react'

import './button.css'

const NextButton = ({ loading, text, classStyles, handleClick }) => {
    return (
        <div className="button-container">
            <Button
                className={classStyles}
                onClick={handleClick}
                loading={loading}
            >{text}</Button>
        </div>
    )
}

export default NextButton
