import React from "react"
import ReactPlayer from "react-player"

import './videoSection.css'

const VideoSection = () => (
    <div className="video-section-container">
        <div className="video-container">
          <div className="video">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=TJjOEghED0A"
              className="react-player"
              width="100%"
              height="70vh"
            />
          </div>
          <div>
            <p>Video Title</p>
          </div>
        </div>

        {/* <div className="video-container">
          <div className="video">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=TJjOEghED0A"
              className="react-player"
              width="100%"
              height="35vh"
            />
          </div>
          <div>
            <p>Video Title</p>
          </div>
        </div>

        <div className="video-container">
          <div className="video-content">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=TJjOEghED0A"
              className="react-player"
              width="100%"
              height="35vh"
            />
          </div>
          <div>
            <p>Video Title</p>
          </div>
        </div> */}
    </div>
)

export default VideoSection