import React, { useState } from 'react'

import GDPRConsent from './GDPRConsent/GDPRConsent'
import ClientInfo from './clientInfo/ClientInfo'
import MachineInfo from './machineInfo/MachineInfo'
import ContactDealer from './contactDrealer/ContactDealer'
import ComfimationMessage from '../common/ComfirmationMessage'
import CloseWindowMessage from '../closeWindowMessage/CloseWindowMessage'
import submitData from '../../helpers/submitData'

const RequestContainer = () => {
    const [formNumber, setFormNumber] = useState(1)
    const [request, setRequest] = useState({})
    const [selected, setSelected] = useState(false)
    const [errors, setErrors] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)

    const openComfirmationMessage = () => {
        setOpen(true)
    }

    const closeComfirmationMessage = () => {
        setOpen(false)
        setFormNumber(5)
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const stopLoading = () => {
        setLoading(false)
    }

    const closeModal = () => {
        setIsOpen(false)
        stopLoading()
    }

    const startLoading = () => {
        setLoading(true)
    }

    const validate = () => {
        let statusError
        let customerContactDealerError
        let cityError
        let addressError
        let postcodeError
        let phoneNumberError

        if(!request.hasOwnProperty('status')){
            statusError = "Please select Yes or No"
            setErrors({...errors, statusError})
            return false
        }

        if(request.status && !request.hasOwnProperty('customerContactDealer')){
            customerContactDealerError = "Please select Yes or No"
            setErrors({...errors, customerContactDealerError})
            return false
        }

        if((request.status && request.customerContactDealer === false) && !request.city){
            cityError = "City can not be empty"
            setErrors({...errors, cityError})
            return false
        }

        if((request.status && request.customerContactDealer === false) && !request.address){
            addressError = "Address can not be empty"
            setErrors({...errors, addressError})
            return false
        }

        if((request.status && request.customerContactDealer === false) && !request.postcode){
            postcodeError = "Postcode can not be empty"
            setErrors({...errors, postcodeError})
            return false
        }

        if((request.status && request.customerContactDealer === false) && !request.phoneNumber){
            phoneNumberError = "Phone number can not be empty"
            setErrors({...errors, phoneNumberError})
            return false
        }

        return true
    }

    const prevForm = () => {
        setFormNumber( formNumber - 1 )
    }

    const maxLengthCheck = (inputValueLength, inputValue, maxLength) => {
        if (inputValueLength > maxLength) {
         inputValue = inputValue.slice(0, maxLength)
        }
        return inputValue
    }
    

    const handleChange = (e) => {
        if(e.target.name === 'serialNumber'){
            const inputValue =  maxLengthCheck(e.target.value.length, e.target.value, e.target.maxLength)
            setRequest({ ...request, serialNumber: inputValue })
        }else if(e.target.name === 'comfirmSerialNumber'){
            const inputValue =  maxLengthCheck(e.target.value.length, e.target.value, e.target.maxLength)
            setRequest({ ...request, comfirmSerialNumber: inputValue })
        }else {
            setRequest({ ...request, [e.target.name]: e.target.value})
        }
        
    }

    const handleRadioChange = (e) => {
        const isTrueSet = (e.currentTarget.value === 'true');
        setRequest({ ...request, [e.target.name]: isTrueSet})
    }

    const handleCheckBoxChange = (e) => {
        setRequest({ ...request, [e.target.name]: e.target.checked})
    }

    const handleSelectChange = (e, data) => {
        if(data.value === 'other'){
            setRequest({ ...request, nipStatus: null })
            setSelected(true)
        }else{
            setSelected(false)
            setRequest({ ...request, [data.name]: data.value})
        }
    }

    const viewInformation = () => {
        setErrors({})
        const isValid = validate()
        if(isValid){
            openModal()
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        startLoading()
        console.log('Requestss', request)
        const sendRequest = await submitData(request)
        if(sendRequest && sendRequest.data && sendRequest.data.status === 201){
            stopLoading()
            setRequest({})
            closeModal()
            openComfirmationMessage()
            setFormNumber(1)
        }
    }

    switch(formNumber) {
        case 1:
            return (
                <>
                <GDPRConsent
                    formNumber = {formNumber}
                    setFormNumber={setFormNumber}
                    handleCheckBoxChange={handleCheckBoxChange}
                    request={request}
                />

                <ComfimationMessage
                    openComfirmationMessage={openComfirmationMessage}
                    closeComfirmationMessage={closeComfirmationMessage}
                    open={open}
                />
                </>
            )
        case 2:
            return (
                <ClientInfo
                    formNumber = {formNumber}
                    setFormNumber={setFormNumber}
                    prevForm={prevForm}
                    handleChange={handleChange}
                    request={request}
                />
            )
        case 3:
            return(
                <MachineInfo
                    formNumber = {formNumber}
                    setFormNumber={setFormNumber}
                    prevForm={prevForm}
                    handleChange={handleChange}
                    handleRadioChange={handleRadioChange}
                    request={request}
                    handleSelectChange={handleSelectChange}
                    handleSubmit={handleSubmit}
                    selected={selected}
                    setRequest={setRequest}
                    closeModal={closeModal}
                    openModal={openModal}
                    isOpen={isOpen}
                    loading={loading}
                    startLoading={startLoading}
                    stopLoading={stopLoading}
                    openComfirmationMessage={openComfirmationMessage}
                />
            )
        case 4:
            return(
                <ContactDealer
                    formNumber = {formNumber}
                    setFormNumber={setFormNumber}
                    prevForm={prevForm}
                    handleChange={handleChange}
                    handleRadioChange={handleRadioChange}
                    request={request}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    closeModal={closeModal}
                    openModal={viewInformation}
                    isOpen={isOpen}
                    loading={loading}
                    startLoading={stopLoading}
                    stopLoading={stopLoading}
                />
            )
        case 5:
            return( <CloseWindowMessage />)
        default :
            <GDPRConsent
                formNumber = {formNumber}
                setFormNumber={setFormNumber}
                handleCheckBoxChange={handleCheckBoxChange}
            />  
    }
}

export default RequestContainer
