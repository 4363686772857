import React from 'react'
import MachineInfoLeft from './MachineInfoLeft'
import MachineInfoRight from './MachineInfoRight'

import '../GDPRConsent/GDPRConsent.css'

const MachineInfo = ({
    setFormNumber,
    formNumber,
    prevForm,
    handleChange,
    handleRadioChange,
    request,
    handleSelectChange,
    handleSubmit,
    selected,
    setRequest,
    closeModal,
    openModal,
    isOpen,
    loading,
    startLoading,
    stopLoading,
    openComfirmationMessage
}) => {
    return (
        <div className="container-request">
            <MachineInfoLeft />
            <MachineInfoRight
                setFormNumber={setFormNumber}
                formNumber={formNumber}
                prevForm={prevForm}
                handleChange={handleChange}
                handleRadioChange={handleRadioChange}
                request={request}
                handleSelectChange={handleSelectChange}
                handleSubmit={handleSubmit}
                selected={selected}
                setRequest={setRequest}
                closeModal={closeModal}
                openModal={openModal}
                isOpen={isOpen}
                loading={loading}
                startLoading={startLoading}
                stopLoading={stopLoading}
                openComfirmationMessage={openComfirmationMessage}
            />
        </div>
    )
}

export default MachineInfo