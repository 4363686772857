import React from 'react'
import { Modal } from 'semantic-ui-react'

import BackButton from '../common/BackButton'
import NextButton from '../common/NextButton'

import './comfirmInfoModal.css'

const ComfirmInfoModal = ({
  openModal,
  closeModal,
  isOpen,
  handleSubmit,
  request,
  loading
}) => {

  return (
    <Modal
      onClose={closeModal}
      onOpen={openModal}
      open={isOpen}
    >
      <Modal.Header>
        <span className="comfirm-header">
          Comfirm your information
        </span>
        </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div className="info-modal-container">
              <div className="info-container-left">
                {request.serialNumber && <div className="row">
                    <div><span>Serial Number</span></div>
                    <div className="info"><span>{request.serialNumber}</span></div>
                </div>}

                {request.hasOwnProperty('hasProduct') && <div className="row">
                    <div><span>Do you still have the machine?</span></div>
                    <div className="info"><span>{request.hasProduct ? 'Yes' : 'NO'}</span></div>
                </div>}

                {request.nipStatus && <div className="row">
                    <div><span>Why you don't have the machine?</span></div>
                    <div className="info"><span>{request.nipStatus}</span></div>
                </div>}

                {request.nipStatus && <div className="row">
                    <div><span>Help us locate the machine?</span></div>
                    <div className="info"><span>{request.nipDetails}</span></div>
                </div>}

                {request.hasOwnProperty('status') && <div className="row">
                    <div><span>Do you believe any wheels to be defective?</span></div>
                    <div className="info"><span>{request.status ? 'Yes' : 'No'}</span></div>
                </div>}

                {request.hasOwnProperty('customerContactDealer') && <div className="row">
                    <div><span>Are you happy contacting a dealer?</span></div>
                    <div className="info"><span>{request.customerContactDealer ? 'Yes' : 'No'}</span></div>
                </div>}
              </div>
              

              <div className="info-container-right">
                  {request.name && <div className="row">
                    <div><span>Name</span></div>
                    <div className="info"><span>{request.name}</span></div>
                  </div>}

                  {request.surname && <div className="row">
                    <div><span>Surname</span></div>
                    <div className="info"><span>{request.surname}</span></div>
                  </div>}

                  {request.email && <div className="row">
                    <div><span>Email</span></div>
                    <div className="info"><span>{request.email}</span></div>
                  </div>}

                  {request.address && <div className="row">
                    <div><span>Address</span></div>
                    <div className="info"><span>{request.address}</span></div>
                  </div>}

                  {request.postcode && <div className="row">
                    <div><span>Postcode</span></div>
                    <div className="info"><span>{request.postcode}</span></div>
                  </div>}

                  {request.phoneNumber && <div className="row">
                    <div><span>Phone Number</span></div>
                    <div className="info"><span>{request.phoneNumber}</span></div>
                  </div>}

                  {request.details && <div className="row">
                    <div><span>Details</span></div>
                    <div className="info"><span>{request.details}</span></div>
                  </div>}

              </div>
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <div className="modal-buttons">
            <BackButton prevForm={closeModal} text="Back" classStyles="back-btn-modal"/>
            <NextButton
              text="Submit"
              classStyles="next-btn"
              handleClick={handleSubmit}
              loading={loading}
            />
        </div>
       
      </Modal.Actions>
    </Modal>
  )
}

export default ComfirmInfoModal