import React, { useState } from 'react'

import NextButton from '../../common/NextButton'
import BackButton from '../../common/BackButton'

import './clientInfo.css'
const ClientInfoRight = ({ prevForm, handleChange, request, formNumber, setFormNumber }) => {
    const [errors, setErrors] = useState({})

    const validate = () => {
        let nameError
        let surnameError
        let emailError
        let comfrimEmailError
        const emailRegEx = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/

        if(!request.name || request.name.trim() === ''){
            nameError = "Name can not be empty"
            setErrors({...errors, nameError})
            return false
        }

        if(!request.surname || request.surname.trim() === ''){
            surnameError = "Surname can not be empty"
            setErrors({...errors, surnameError})
            return false
        }

        if(!request.email || request.email.trim() === ''){
            emailError = "Email can not be empty"
            setErrors({...errors, emailError})
            return false
        }

        if(!request.email.match(emailRegEx)){
            emailError = "Please provide a vaild email"
            setErrors({...errors, emailError})
            return false
        }

        if(!request.comfirmEmail || request.email.trim() === ''){
            comfrimEmailError = "Comfirm email can not be empty"
            setErrors({...errors, comfrimEmailError})
            return false
        }

        if(request.email !== request.comfirmEmail){
            comfrimEmailError = "Email and comfirmation email do not match"
            setErrors({...errors, comfrimEmailError})
            return false
        }
        return true
    }

    const nextForm = () => {
        setErrors({})
        const isValid = validate()
        if(isValid){
            setFormNumber( formNumber + 1 )
            setErrors({})
        }
    }

    return (
        <div className="client-info-container">
            <div className='client-info-form'>
                <input
                    type='text'
                    name='name'
                    placeholder='Name'
                    className="input-text"
                    autoComplete="off"
                    value={request.name}
                    onChange={handleChange}
                />

                {errors.hasOwnProperty('nameError') &&
                <span style={{margin: "10px 0 -20px 12px", color:'#ef7b10'}}>
                    {errors.nameError}
                </span>}

                <input
                    type='text'
                    name='surname'
                    placeholder='Surname'
                    className="input-text"
                    autoComplete="off"
                    value={request.surname}
                    onChange={handleChange}
                />

                {errors.hasOwnProperty('surnameError') &&
                <span style={{margin: "10px 0 -20px 12px", color:'#ef7b10'}}>
                    {errors.surnameError}
                </span>}

                <input
                    type='text'
                    name='email'
                    placeholder='Email'
                    className="input-text"
                    autoComplete="off"
                    value={request.email}
                    onChange={handleChange}
                />

                {errors.hasOwnProperty('emailError') &&
                <span style={{margin: "10px 0 -20px 12px", color:'#ef7b10'}}>
                    {errors.emailError}
                </span>}

                <input
                    type='text'
                    name='comfirmEmail'
                    placeholder='Comfirm Email'
                    className="input-text"
                    autoComplete="off"
                    value={request.comfirmEmail}
                    onChange={handleChange}
                />

                {errors.hasOwnProperty('comfrimEmailError') &&
                <span style={{margin: "10px 0 0 12px", color:'#ef7b10'}}>
                    {errors.comfrimEmailError}
                </span>}

                <div className="btn-container">
                    <BackButton
                        prevForm={prevForm}
                        text="Back"
                        classStyles="back-btn"
                    />

                    <NextButton
                        handleClick={ nextForm }
                        text="Next"
                        classStyles="next-btn"
                    />
                </div>

                
            </div>
            
        </div>
    )
}

export default ClientInfoRight
