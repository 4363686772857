import React from 'react'

import NextButton from '../../common/NextButton'
import BackButton from '../../common/BackButton'
import ComfirmInfoModal from '../../common/ComfirmInfoModal'

import '../clientInfo/clientInfo.css'
import '../machineInfo/MachineInfo.css'
import './ContactDealer.css'


const ContactDealer = ({
    prevForm,
    request,
    handleRadioChange,
    handleChange,
    handleSubmit,
    errors,
    closeModal,
    openModal,
    isOpen,
    loading
}) => {
    return (
        <>
        <div className="client-info-container">
            <div className='client-info-form'>
                <div className="have-the-machine-container">
                    <p>Do you believe any wheels to be defective?</p>
                    <input
                        type='radio'
                        style={{cursor: "pointer"}}
                        name='status'
                        id='yes'
                        value={true}
                        checked={request.status === true}
                        onChange={handleRadioChange}
                    />
                    <label for="yes">Yes</label>

                    <input
                        type='radio'
                        style={{cursor: "pointer"}}
                        name='status'
                        id='no'
                        value={false}
                        checked={request.status === false}
                        onChange={handleRadioChange}
                    />
                    <label for="no">No</label>
                </div>

                {errors.hasOwnProperty('statusError') &&
                <span className="error-margins">
                    {errors.statusError}
                </span>}

                {request.status &&
                <div className="have-the-machine-container">
                    <p>Are you happy contacting your nearest Kubota dealer yourself?</p>
                    <input
                        type='radio'
                        style={{cursor: "pointer"}}
                        name='customerContactDealer'
                        id='yes'
                        value={true}
                        checked={request.customerContactDealer === true}
                        onChange={handleRadioChange}
                    />
                    <label for="yes">Yes</label>

                    <input
                        type='radio'
                        style={{cursor: "pointer"}}
                        name='customerContactDealer'
                        id='no'
                        value={false}
                        checked={request.customerContactDealer === false}
                        onChange={handleRadioChange}
                    />
                    <label for="no">No</label>
                </div>}

                {errors.hasOwnProperty('customerContactDealerError') &&
                <span className="error-margins">
                    {errors.customerContactDealerError}
                </span>}

                {request.customerContactDealer === false  &&
                <p className="p-space">
                    We will arrange for your nearest Kubota dealer to contact you.  Please provide some further details about yourself so they can contact you.
                </p>}

                {request.customerContactDealer === false &&
                <div className="personal-info">
                <input
                    type='text'
                    name='address'
                    placeholder='Address'
                    className="input-text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={request.address}
                    style={{'marginTop': "0"}}
                />

                {errors.hasOwnProperty('addressError') &&
                <span className="error-message">
                    {errors.addressError}
                </span>}

                <input
                    type='text'
                    name='city'
                    placeholder='City'
                    className="input-text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={request.city}
                />
                {errors.hasOwnProperty('cityError') &&
                <span className="error-message">
                    {errors.cityError}
                </span>}

                <input
                    type='text'
                    name='postcode'
                    placeholder='Postcode'
                    className="input-text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={request.postcode}
                />

                {errors.hasOwnProperty('postcodeError') &&
                <span className="error-message">
                    {errors.postcodeError}
                </span>}

                <input
                    type='text'
                    name='phoneNumber'
                    placeholder='Phone Number'
                    className="input-text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={request.phoneNumber}
                />

                {errors.hasOwnProperty('phoneNumberError') &&
                <span className="error-message">
                    {errors.phoneNumberError}
                </span>}

                <textarea
                    name='details'
                    placeholder='Tell us more...'
                    className="input-area"
                    autoComplete="off"
                    onChange={handleChange}
                    value={request.details}
                />

                </div>}

                {request.customerContactDealer === true  &&
                <p className="p-space">You can search for your nearest&nbsp;
                    <a href="https://www.kubotadealers.co.uk" target="_new">Kubota dealer</a>
                </p>}

                <div>
                    <BackButton
                        prevForm={ prevForm }
                        text="Back"
                        classStyles="back-btn"
                    />

                    {<NextButton
                        text="Review"
                        classStyles="next-btn"
                        handleClick={openModal}
                    />}
                </div>
            </div>
        </div>
        <ComfirmInfoModal
            closeModal={closeModal}
            openModal={openModal}
            isOpen={isOpen}
            handleSubmit={handleSubmit}
            request={request}
            loading={loading}
        />
        </>
    )
}

export default ContactDealer
