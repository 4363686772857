import React, { useState } from 'react'
import { Select, Popup, Button, Icon } from 'semantic-ui-react'

import serialNumberExample from '../../../images/serial-number.jpg'
import NextButton from '../../common/NextButton'
import BackButton from '../../common/BackButton'
import ComfirmInfoModal from '../../common/ComfirmInfoModal'
import ErrorModal from '../../common/ErrorModal'
import getOneRequest from '../../../helpers/getOneRequest'
import submitData from '../../../helpers/submitData'

import '../clientInfo/clientInfo.css'
import './MachineInfo.css'

const countryOptions = [
    { key: 'a', value: 'Sold Machine', text: 'Sold Machine' },
    { key: 'b', value: 'Scrapped', text: 'Scrapped' },
    { key: 'c', value: 'Stolen/Lost', text: 'Stolen/Lost' },
    { key: 'd', value: 'other', text: 'Other reason' },
]

const MachineInfoRight = ({
    formNumber,
    setFormNumber,
    prevForm,
    handleRadioChange,
    request,
    handleSelectChange,
    handleChange,
    selected,
    setRequest,
    closeModal,
    openModal,
    isOpen,
    loading,
    startLoading,
    stopLoading,
    openComfirmationMessage
}) => {
    const [errors, setErrors] = useState({})
    const [open, setOpen] = useState(false)
    const [loadingRequest, setLoadingRequest] = useState(false)

    const getRequest = async() => {
        setLoadingRequest(true)
        const {email, serialNumber} = request
        const oneRequest = await getOneRequest(email, serialNumber)
        setLoadingRequest(false)
        const {status} = oneRequest

        if(status && status === 200){
            return true
        }else{
            return false
        }
    }

    const openErrorModal = () => {
        setOpen(true)
    }

    const closeErrorModal = () => {
        setOpen(false)
        setRequest({})
        setFormNumber(1)
    }

    const validate = () => {
        let comfirmSerialNumberError
        let serialNumberError
        let hasProductError
        let nipStatusError
        let nipStatusErrorOther
        let nipDetailsError

        if(!request.serialNumber){
            serialNumberError = "Serial number can not be empty"
            setErrors({...errors, serialNumberError})
            return false
        }

        if(request.serialNumber.toString().length < 5 || request.serialNumber.toString().length > 5){
            serialNumberError = "Serial number must have 5 digits"
            setErrors({...errors, serialNumberError})
            return false
        }

        if(!request.comfirmSerialNumber){
            comfirmSerialNumberError = "Confirm serial number can not be empty"
            setErrors({...errors, comfirmSerialNumberError})
            return false
        }

        if(request.comfirmSerialNumber.toString().length < 5){
            comfirmSerialNumberError = "comfirm serial number must have 5 digits"
            setErrors({...errors, comfirmSerialNumberError})
            return false
        }

        if(request.comfirmSerialNumber !== request.serialNumber){
            comfirmSerialNumberError = "Serial number and confirm serial number do not match"
            setErrors({...errors, comfirmSerialNumberError})
            return false
        }

        if(!request.hasOwnProperty('hasProduct')){
            hasProductError="Please select Yes or No"
            setErrors({...errors, hasProductError})
            return false
        }

        if(!request.hasProduct && !request.nipStatus){
            nipStatusError="Please tell us why you don't have the machine"
            setErrors({...errors, nipStatusError})
            return false
        }

        if(!request.hasProduct && request.nipStatus === 'other'){
            nipStatusErrorOther="Please tell us why you don't have the machine"
            setErrors({...errors, nipStatusErrorOther})
            return false
        }

        if(!request.hasProduct && !request.nipStatus){
            nipDetailsError="Please tell us where we can find the machine"
            setErrors({...errors, nipDetailsError})
            return false
        }
        return true
    }

    const viewInfo = async(e) => {
        const isValid = validate()
        if(isValid){

            const madeRequest = await getRequest()
            if(madeRequest){
                openErrorModal()
            }else{
                openModal()
                setErrors({})
            }
        }
    }

    const submitForm = async(e) => {
        startLoading()
        const sendRequest = await submitData(request)

        if(sendRequest && sendRequest.data && sendRequest.data.status === 201){
            stopLoading()
            setRequest({})
            closeModal()
            openComfirmationMessage()
            setFormNumber(1)
        }
    }

    const nextForm = async() => {
        setErrors({})
        const isValid = validate()
        if(isValid){
            const madeRequest = await getRequest()
            if(madeRequest){
                openErrorModal()
            }else{
                setFormNumber( formNumber + 1 )
                setErrors({})
            }
        }
    }

    return (
        <>
        <div className="client-info-container">
            <div className='client-info-form'>
            <input
                    type='number'
                    name='serialNumber'
                    placeholder='Your 5 digit serial number'
                    className="input-text"
                    autoComplete="off"
                    maxlength="5"
                    value={request.serialNumber}
                    onChange={handleChange}
                />
                {errors.hasOwnProperty('serialNumberError')&& 
                    <span className="error-message">
                        {errors.serialNumberError}
                    </span>}
                <input
                    type='number'
                    name='comfirmSerialNumber'
                    placeholder='Comfirm serial number'
                    className="input-text"
                    autoComplete="off"
                    maxlength="5"
                    value={request.comfirmSerialNumber}
                    onChange={handleChange}
                />

                {errors.hasOwnProperty('comfirmSerialNumberError') &&
                    <span className="error-message">
                        {errors.comfirmSerialNumberError}
                    </span>}
                
                <Popup
                    content={
                        <div>
                            <img 
                                className="serial-number-example"
                                src={serialNumberExample}
                                alt="Serail number example"
                            />
                        </div>}
                    trigger={<Button className="open-popup">
                                <div className="btn-text">
                                    <span>View Serial number example</span>
                                    <Icon name="eye"/>
                                </div>
                            </Button>}
                />

                <div className="have-the-machine-container">
                    <p>Do you still have the machine?</p>
                    <input
                        type='radio'
                        style={{cursor: "pointer"}}
                        name='hasProduct'
                        id='yes'
                        value={true}
                        checked={request.hasProduct === true}
                        onChange={handleRadioChange}
                    />
                    <label for="yes">Yes</label>

                    <input
                        type='radio'
                        style={{cursor: "pointer"}}
                        name='hasProduct'
                        id='no'
                        value={false}
                        checked={request.hasProduct === false}
                        onChange={handleRadioChange}
                    />
                    <label for="no">No</label>
                </div>
                {errors.hasOwnProperty('hasProductError') &&
                    <span className="error-margins">
                        {errors.hasProductError}
                    </span>}
                {request.hasProduct === false &&
                <div className="do-not-have-machine">
                    <Select
                        placeholder={selected ? 'Other reason' : 'Why you do not have the machine?'}
                        name='nipStatus'
                        options={countryOptions}
                        style={{ border: "none"}}
                        onChange={handleSelectChange}
                        value={request.nipStatus}
                    />
                    {errors.hasOwnProperty('nipStatusError') &&
                    <span className="error-message">
                        {errors.nipStatusError}
                    </span>}
                    {selected &&
                    <>
                    <textarea
                        name='nipStatus'
                        placeholder='Other reason'
                        className="input-area"
                        autoComplete="off"
                        onChange={handleChange}
                        value={request.nipStatus}
                    />
                    {errors.hasOwnProperty('nipStatusErrorOther') &&
                    <span className="error-message">
                        {errors.nipStatusErrorOther}
                    </span>}
                    </>
                    }

                    <textarea
                        type='textarea'
                        name='nipDetails'
                        placeholder='Help us locate your machine'
                        className="input-area"
                        autoComplete="off"
                        onChange={handleChange}
                        value={request.nipDetails}
                    />
                    {errors.hasOwnProperty('nipDetailsError') &&
                    <span className="error-message">
                        {errors.nipDetailsError}
                    </span>}
                </div>}

                <div className="btn-container">
                    <BackButton
                        prevForm={ prevForm }
                        text="Back"
                        classStyles="back-btn"
                    />

                    {!request.hasProduct ? <NextButton
                        handleClick={viewInfo}
                        text="Review"
                        classStyles="next-btn"
                        loading={loadingRequest}
                    />:

                    <NextButton
                        handleClick={nextForm}
                        text="Next"
                        classStyles="next-btn"
                        loading={loadingRequest}
                    />

                    }
                </div>
            </div>
        </div>
        <ComfirmInfoModal
            closeModal={closeModal}
            openModal={openModal}
            isOpen={isOpen}
            handleSubmit={submitForm}
            request={request}
            loading={loading}
        />
        <ErrorModal
            closeModal={closeErrorModal}
            openModal={openErrorModal}
            open={open}
        />
        </>
    )
}

export default MachineInfoRight
