import React,  { useRef } from 'react'
import Header from './components/header/Header'
import VideoSection from './components/videSection/VideoSection'
import RequestContainer from './components/request/RequestContainer'

const  App = () => {
  const requestSection = useRef()

  const scrollToRequestSection = () => {
    requestSection.current.scrollIntoView({
      behavior: "smooth"
    })
  }
  return (
    <div>
      <Header scrollToBottom={scrollToRequestSection} />
      <VideoSection />
      <div ref={requestSection}>
        <RequestContainer/>
      </div>
      
    </div>
  );
}

export default App;
