import React from 'react'

import NextButton from '../../common/NextButton'

const GDPRConsentRight = ({ handleCheckBoxChange, request, setFormNumber, formNumber }) => {
    const nextForm = () => {
        setFormNumber( formNumber + 1 )
    }
    return (
        <div className="conset-content">
            <div className="text-content">
                <p>We need your consent to collect your personal information.  By allowing these third party services, you accept their cookies and the use of tracking technologies necessary for their proper functioning.</p>
                <p className="mgn-top">Please read our <a href="https://kuk.kubota-eu.com/privacy-notice-en/" target="_new">Privacy Policy</a> for more information.</p>
            </div>

            <div className="conset-check">
            <label class="checkbox">
                <input
                    type="checkbox"
                    name="gdprConset"
                    className="check-box-input"
                    checked={request.gdprConset}
                    onChange={handleCheckBoxChange}
                />
                <span>I consent to Kubota collecting my personal information.</span>
                </label>
            </div>

            <NextButton
                handleClick={nextForm}
                text="Next"
                classStyles="next-btn"
            />
        </div>
    )
}

export default GDPRConsentRight
