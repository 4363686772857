import React from 'react'
import ContactDealerLeft from './ContactDealerLeft'
import ContactDealerRight from './ContactDealerRight'

import '../GDPRConsent/GDPRConsent.css'

const ContactDealer = ({
    nextForm,
    prevForm,
    request,
    handleRadioChange,
    handleChange,
    handleSubmit,
    errors,
    closeModal,
    openModal,
    isOpen,
    loading
}) => {
    return (
        <div className="container-request">
            <ContactDealerLeft request={request}/>
            <ContactDealerRight
                nextForm={nextForm}
                prevForm={prevForm}
                handleRadioChange={handleRadioChange}
                request={request}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                errors={errors}
                closeModal={closeModal}
                openModal={openModal}
                isOpen={isOpen}
                loading={loading}
            />
        </div>
    )
}

export default ContactDealer