import React from 'react'
import GDPRConsentLeft from './GDPRConsentLeft'
import GDPRConsentRight from './GDPRConcentRight'

import './GDPRConsent.css'

const GDPRConsent = ({handleCheckBoxChange, request, formNumber, setFormNumber}) => {
    return (
        <div className="container-request">
            <GDPRConsentLeft />
            <GDPRConsentRight
                formNumber = {formNumber}
                setFormNumber={setFormNumber}
                handleCheckBoxChange={handleCheckBoxChange}
                request={request}
            />
        </div>
    )
}

export default GDPRConsent
