import React from 'react'
import { Button, Modal, Icon } from 'semantic-ui-react'

import './comfirmMessage.css'

const ComfirmMessage = ({openModal, closeModal, open}) => {

    return (
        <Modal
            size='tiny'
            onOpen={openModal}
            onClose={closeModal}
            open={open}
            className="comfirmation-message"
        >
            <Modal.Content>
                <h5 className='error-title'>Forbiden!</h5>
                <p className="paragraph-message">
                    This vehicle has already been reported with this email address,
                    please email <a href='mailto:kuk_g.service@kubota.com'>kuk_g.service@kubota.com</a> if you believe this to be incorrect.
                </p>
                <Icon name="times circle outline" size="huge" className="success-icon"/>
            </Modal.Content>
            <Modal.Actions className="action-container">
                <Button className="close-modal-btn btn-color-red" onClick={closeModal}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ComfirmMessage
