import React from 'react'
import ClientInfoLeft from './ClientInfoLeft'
import ClientInfoRight from './ClientInfoRight'

import '../GDPRConsent/GDPRConsent.css'

const ClientInfo = ({ prevForm, handleChange, request, formNumber, setFormNumber }) => {
    return (
        <div className="container-request">
            <ClientInfoLeft />
            <ClientInfoRight
                formNumber = {formNumber}
                setFormNumber={ setFormNumber }
                prevForm={prevForm}
                handleChange={handleChange}
                request={request}
            />
        </div>
    )
}

export default ClientInfo