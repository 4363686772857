import React from 'react'
import kubotoLogo from '../../../images/kuboto-logo.jpg'

 const ContactDealerLeft = ({request}) => {
    return (
        <div className="request-left-side">
            <div>
                <div className="logo-container">
                    <img src={kubotoLogo} alt='Kuboto logo' />
                </div>
                <div className="left-text">
                    <p>{request.customerContactDealer === false ? "Give us some information about yourself" : "Contacting a dealer"}</p>
                </div>
            </div>
        </div>
    )
}

export default ContactDealerLeft
