import React from 'react'
import machineImage from '../../images/GR1600-II-both.jpg'
import kubotoLogo from '../../images/kuboto-logo.jpg'

import './header.css'

const Header = ({ scrollToBottom }) => {
    return (
        <div className="header-section">
            <div className="left-side">
                <div className="logo-container">
                    <img src={kubotoLogo} alt='Kuboto logo' />
                </div>
                <div className="call-to-action">
                    <div className="call-to-action-text">
                    <p>
                        We appreciate your help establishing whether your Kubota GR1600 has defective wheels.  Please watch the explanatory video below and fill in the form, which should only take 3-4 minutes.
                    </p>
                    </div>
                    <div className="call-to-action-btn">
                    <button className="start-btn" onClick={scrollToBottom}>Get started</button>
                    </div>
                </div>
            </div>
            <div className="header-machine-image-containter">
                <img className="image-container" src={machineImage} alt="Machine"/>
            </div>
        </div>
    )
}

export default Header
