import React from 'react'
import CloseWindowLeft from './CloseWindowLeft'
import CloseWindowRight from './CloseWindowRight'

// import '../request/GDPRConsent/GDPRConsent.css'
import './closeWindow.css'

const CloseWindowMessage = () => {
    return (
        <div className="container-request">
            <CloseWindowLeft />
            <CloseWindowRight />
        </div>
    )
}

export default CloseWindowMessage