import React from 'react'
import kubotoLogo from '../../images/kuboto-logo.jpg'

 const CloseWindowLeft = () => {
    return (
        <div className="request-left-side">
        <div className="logo-container">
            <img src={kubotoLogo} alt='Kuboto logo' />
        </div>
            <div className="left-text">
                <p>You are done</p>
            </div>
        </div>
    )
}

export default CloseWindowLeft